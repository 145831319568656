import "./contact.scss";
import insta from "../../images/sm4.svg";
import fb from "../../images/sm3.svg";
import twit from "../../images/sm2.svg";
import li from "../../images/sm1.svg";

const Contact = () => {
  return (
    <div className="contact" id="contact">
      <div className="contact__body">
        <div className="contact__body__prehead" />
        <div className="contact__body__heading">
          We'd <span>love</span> to hear from you
        </div>
        <div className="contact__body__desc">
          <div className="contact__body__desc__text">
            For any queries or clarifications, please contact us{" "}
            <p className="center-text">At</p>
            <p> Kapoor Engineers Pvt Ltd. by clicking on the button below</p>
            <div className="contact__body__connect">
              <a href="mailto:kapoorshivam100@gmail.com">
                <button className="button-24">Connect with us</button>
              </a>
            </div>
          </div>
        </div>

        <div className="contact__body__social-media">
          <div>
            <img height={28} src={insta} alt="insta" />
            <img height={28} src={fb} alt="fb" />
            <img height={28} src={twit} alt="twit" />
            <img height={28} src={li} alt="li" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
