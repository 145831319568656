import "./footer.scss";
import brandLogo from "../../../images/brand-name-2.png";
import insta from "../../../images/sm4.svg";
import fb from "../../../images/sm3.svg";
import twit from "../../../images/sm2.svg";
import li from "../../../images/sm1.svg";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__details">
        <div>
          <img
            src={brandLogo}
            height={window.screen.width < 865 ? 40 : 80}
            alt="KapoorEngineers"
            className="brand-img"
          />
        </div>
        <div></div>

        <div></div>
      </div>
      <div className="footer__details2">
        <div className="footer__details2__para">
          <br />
          <div className="footer__heading">Contact</div>
          <div>
            <span className="no-display-on-mobile"></span>
            kapoorshivam100@gmail.com <br />
          </div>
        </div>
        <div className="footer__details2__para no-display-on-mobile"></div>
        <div className="footer__details2__para no-display-on-mobile">
          <div>
            <div className="footer__social-media">
              <div className="no-display-on-mobile">
                <img height={28} src={insta} alt="insta" />
                <img height={28} src={fb} alt="fb" />
                <img height={28} src={twit} alt="twit" />
                <img height={28} src={li} alt="li" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__copyright">
        &copy; Kapoor Engineers, 2024, All rights reserved.{" "}
      </div>
    </div>
  );
};

export default Footer;
