import "./carousel.css";
import CarouselCard from "../CarouselCard";
import halLogo from "../../../images/halLogo.svg";
import iafLogo from "../../../images/iafLogo.png";
import navyLogo from "../../../images/navyLogo.png";
import nsLogo from "../../../images/nsLogo.png";
import travianLogo from "../../../images/travianLogo.jpg";
import threyeLogo1 from "../../../images/threyeLogo1.png";
import manrichLogo from "../../../images/manrichLogo.png";
import { useState, useEffect } from "react";

const carouselItems = [
  {
    img: halLogo,
    text: "Hindustan Aeronauticals Ltd",
  },
  {
    img: iafLogo,
    text: "Indian Airforce",
  },
  {
    img: navyLogo,
    text: "Indian Navy",
  },
  {
    img: nsLogo,
    text: "New Space Research & Technologies",
  },
  {
    img: threyeLogo1,
    text: "Threye Interactive",
  },
  {
    img: travianLogo,
    text: "Travian Flights",
  },
  {
    img: manrichLogo,
    text: "Manrich Special Ops",
  },
];

const Carousel = () => {
  const [cards, setCards] =
    useState<{ text: string; img: string }[]>(carouselItems);

  useEffect(() => {
    const interval = setInterval(() => {
      setCards((prevCards) => {
        const newCards = [...prevCards];
        const firstCard = newCards.shift();
        firstCard && newCards.push(firstCard);
        return newCards;
      });
    }, 2000); // Adjust the interval as needed
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="carousel">
      {cards.map((card, index) => (
        <div className="card" key={index}>
          <CarouselCard text={card?.text} img={card?.img} />
          <div className="card-text">{card?.text}</div>
        </div>
      ))}
    </div>
  );
};

export default Carousel;
