import "./CarouselCard.scss";
const CarouselCard = ({ img, text }: { img: string; text: string }) => {
  return (
    <div className="rc-carousel-item">
      <img src={img} alt={text} className={`rc-carousel-item-image`} />
    </div>
  );
};

export default CarouselCard;
