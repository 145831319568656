import img1 from "../images/sim2.jpg";
import img2 from "../images/project2.png";
// import img3 from  "../images/project3.png";
import img4 from "../images/project5.png";
import img5 from "../images/defence.png";
export const ourProjects = [
  { title: "Mirage 2000 Simulator", img: img1 },
  { title: "Winch Rescue Stretcher", img: img2 },
  { title: "Legacy Hardware Modernisation", img: img5 },
  { title: "Multi-function lightweight Scissor Ladder", img: img2 },
  { title: "Automatic Industrial Hydraulic Machines", img: img4 },
];
