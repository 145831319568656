import ServiceCard from "../../shared/components/ServiceCard";
import WavesPageDivider from "../../shared/components/WavesPageDivider";
import "./home.scss";
import defenceImg from "../../images/defence.png";
import dataImg from "../../images/data-img.jpg";
import simulatorImg from "../../images/simulator.svg";
import { ourCapabilities } from "../../constants/ourCapabilities";
import iconGif from "../../images/modelling.png";
import Projects from "../Projects";
import Contact from "../Contact";
import Clients from "../../shared/components/Clients";

const Home = () => {
  return (
    <>
      <div className="home-landing-page" id="home">
        <div className="home-landing-page__left">
          <div>
            <div className="home-landing-page__left__1">
              Innovative Engineering
            </div>
            <div className="home-landing-page__left__2">
              Solutions for Defense
            </div>
            <div className="home-landing-page__left__3">
              {" "}
              Specialized in the manufacturing of Electro-Mechanical components
              like simulators, helicopter rescue stretchers and AI-based
              solutions for armed forces.{" "}
            </div>
          </div>
        </div>
        <div className="home-landing-page__right"></div>
      </div>
      <WavesPageDivider />
      <div className="home-pg-2" id="expertise">
        <div className="home-pg-2__heading" id="about">
          How we help you with <span>our expertises</span>
        </div>
        <div className="home-pg-2__body">
          <ServiceCard
            title="Tailored Electronic Solutions for Defense Equipment"
            img={defenceImg}
          />
          <ServiceCard
            title="Internal competencies inclusive of AI and simulation technologies"
            img={dataImg}
          />
          <ServiceCard
            title="Indigenous defense production featuring a diverse product range"
            img={simulatorImg}
          />
        </div>
      </div>

      <div className="home-pg-3" id="services">
        <div className="home-pg-3__hr"></div>
        <div className="home-pg-3__heading center-the-div">
          <div className="home-pg-3__get-in-touch">
            What <span> we </span>offer
          </div>
        </div>
        <div className="home-pg-3__body">
          <div className="home-pg-3__body__img">
            <div className="what-we-offer-img"></div>
          </div>
          <div className="home-pg-3__body__list">
            <div className="home-pg-3__body__list__contain">
              {ourCapabilities?.map((capability) => (
                <div className="capability-list-item">
                  <div>
                    <img src={iconGif} alt="icon" height={36} width={36} />
                  </div>
                  <div className="text">{capability}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Clients />
      <Projects />
      <Contact />
    </>
  );
};

export default Home;
