import { ourProjects } from "../../constants/ourProjects";
import "./projects.scss";
import ServiceCard from "../../shared/components/ServiceCard";

const Projects = () => {
  return (
    <div className="projects">
      <div className="projects__heading">
        {" "}
        Explore <span>our</span> Projects
      </div>
      <div className="projects__body">
        {ourProjects?.map((project) => (
          <ServiceCard title={project?.title} img={project?.img} isProject />
        ))}
      </div>
    </div>
  );
};

export default Projects;
