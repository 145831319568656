import Gallery from "../view/Gallery";
import { RouterProps } from "../shared/types/route.type";
import About from "../view/About";
import Contact from "../view/Contact";
import Home from "../view/Home";
import Projects from "../view/Projects";
import Services from "../view/Services";
import { AppRoutes } from "./routeConstants/appRoutes";

const routes: RouterProps[] = [
  { path: AppRoutes.HOME, component: <Home /> },
  { path: AppRoutes.ABOUT, component: <About /> },
  { path: AppRoutes.SERVICES, component: <Services /> },
  { path: AppRoutes.PROJECTS, component: <Projects /> },
  { path: AppRoutes.CONTACT, component: <Contact /> },
  { path: AppRoutes.Gallery, component: <Gallery /> },
  { path: "*", component: <Home /> },
];

export default routes;
