import "./serviceCard.scss";

const ServiceCard = ({
  title,
  img,
  isProject,
}: {
  title: string;
  img: string;
  isProject?: boolean;
}) => {
  console.log(window.screen.width);
  return (
    <div
      className={`service-card ${
        isProject && window.screen.width < 865 && "project-card"
      }`}
    >
      <div className="service-card__img">
        <img
          src={img}
          alt="service"
          width={isProject && window.screen.width < 865 ? 152 : 226}
          height={isProject && window.screen.width < 865 ? 130 : 168}
        />
      </div>
      <div className="service-card__title">{title}</div>
    </div>
  );
};

export default ServiceCard;
