import "./navbar.scss";
import BrandName from "../../../images/brand-name.png";
import { Link, useLocation } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { useState } from "react";
import menuIcon from "../../../images/sidebar-icon.png";

const Navbar = () => {
  const { hash, pathname } = useLocation();
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] =
    useState<boolean>(false);
  return (
    <div className="navbar-container">
      <div className="navbar-container__brand-label">
        <Link to={AppRoutes.HOME}>
          <img
            src={BrandName}
            alt="Kapoor Engineers"
            height={window.screen.width < 875 ? 60 : 78}
          />
        </Link>
      </div>
      <div
        className={`navbar-container__menubar  menu-toggle ${
          !isMobileSidebarOpen && "sm-disp-none"
        }`}
      >
        <div className="sidebar-top">
          <div
            className="close-sidebar"
            onClick={() => setIsMobileSidebarOpen(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="9"
              viewBox="0 0 50 50"
              overflow="visible"
              stroke="#1f2d85"
              stroke-width="10"
              stroke-linecap="round"
            >
              <line x2="50" y2="50" />
              <line x1="50" y2="50" />
            </svg>
          </div>
          <div>
            <Link to={AppRoutes.HOME}>
              <img
                src={BrandName}
                alt="Kapoor Engineers"
                height={window.screen.width < 875 ? 60 : 78}
              />
            </Link>
          </div>
        </div>

        <a
          href={pathname === "/gallery" ? "/" : "#home"}
          onClick={() => setIsMobileSidebarOpen(false)}
          className={`navbar-container__menubar__menu-item ${
            hash === "#home" && "border-bottom"
          }`}
        >
          Home
        </a>
        <a
          onClick={() => setIsMobileSidebarOpen(false)}
          href={pathname === "/gallery" ? "/" : "#services"}
          className={`navbar-container__menubar__menu-item ${
            hash === "#services" && "border-bottom"
          }`}
        >
          What we offer
        </a>
        <a
          onClick={() => setIsMobileSidebarOpen(false)}
          href={pathname === "/gallery" ? "/" : "#projects"}
          className={`navbar-container__menubar__menu-item ${
            hash === "#projects" && "border-bottom"
          }`}
        >
          Our Clients
        </a>
        <a
          onClick={() => setIsMobileSidebarOpen(false)}
          href={"/gallery"}
          className={`navbar-container__menubar__menu-item ${
            pathname === "/gallery" && "border-bottom"
          }`}
        >
          Gallery
        </a>
        <a
          href={pathname === "/gallery" ? "/" : "#contact"}
          onClick={() => setIsMobileSidebarOpen(false)}
          className={
            hash === "#contact"
              ? "border-bottom contact-button"
              : "contact-button"
          }
        >
          <button className="button-2">Contact Us</button>
        </a>
      </div>
      <div
        className="sidebar-menu-icon"
        onClick={() => setIsMobileSidebarOpen(!isMobileSidebarOpen)}
      >
        <img src={menuIcon} height={38} alt={menuIcon} />
      </div>
    </div>
  );
};

export default Navbar;
