import "./clients.scss";
import Carousel from "../Carousel";
const Clients = () => {
  return (
    <div className="client-outer" id="projects">
      <div className="projects__prehead" />
      <div className="client-outer__bg-heading">OUR CLIENTS</div>
      <div className="client-outer__heading">
        Take a <span>look</span> at our clients
      </div>
      <div className="client-outer__carousel">
        <Carousel />
      </div>
    </div>
  );
};

export default Clients;
